<template>
  <van-button @click="openUrl()" class="mt fa-btn" style="border:1px solid #C90000;background-color: #fff;color:#C90000;" size="large" round type="danger">打开</van-button>
</template>

<script setup>
import {} from 'vant';
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
import Menu from '../../components/Menu.vue'
import {useRoute,useRouter} from 'vue-router'
import api from '../../common/http.js';
import {isNull,showMessage,nullStr} from '../../common/utils.js'
import KfCard from '../../components/KfCard.vue'
import ShareCard from '../../components/ShareCard.vue'
import { areaList } from '@vant/area-data';

// function openUrl(){
//     location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4c332db0684b653b&redirect_uri=http%3A%2F%2Fwww.keyunyun.com.cn%2Fcode&scope=snsapi_userinfo&state=http%3A%2F%2Fwww.keyunyun.com.cn%2Fproducttable&response_type=code#wechat_redirect";
// }
localStorage.setItem("destIndex","https://iwx.keyunyun.com.cn/bxme");
</script>

<style>

</style>